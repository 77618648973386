import type { HeadlineResponse } from '@on3/api';
import { On3IconPremiumPlus } from '@on3/icons';
import { toFriendlyDate } from '@on3/ui-lib/src/utils/date';
import clsx from 'clsx';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './ArticleCover.module.scss';

interface IExtendedArticleProps extends HeadlineResponse {
  mode?: 'dark' | 'light';
  page?: 'network' | 'channel' | 'team';
  index?: number;
}

export const ArticleCover = ({
  featuredImage,
  title,
  fullUrl,
  author,
  page = 'network',
  postDate,
  index,
  mode = 'light',
  primaryCategory,
  isPremium,
}: IExtendedArticleProps) => {
  const classes = mode === 'dark' ? 'dark' : 'light';

  let baseUrl = primaryCategory?.fullUrl;

  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return (
    <article>
      {featuredImage && (
        <TextLink className={styles.imagelink} href={fullUrl}>
          <ResizedImage
            className={clsx({
              [styles.image]: true,
              [styles.imageSecondary]: index,
            })}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.3"
            height={204}
            loading="eager"
            quality={90}
            width={413}
          />
        </TextLink>
      )}
      <TextLink
        className={clsx(styles.category, styles[page])}
        color={mode === 'dark' ? 'primary' : 'initial'}
        href={`${baseUrl}/news/`}
      >
        {primaryCategory?.name}
      </TextLink>
      <TextLink className={styles.titlelink} href={fullUrl}>
        <Text
          className={clsx({
            [styles.title]: true,
            [styles[classes]]: true,
            [styles.titleOrder]: index,
          })}
          variant="h6"
        >
          {title}
        </Text>
      </TextLink>

      <div className={styles.authorblock}>
        <TextLink
          className={clsx(styles.author, styles[classes])}
          href={`/user/${author?.niceName}`}
          variant="caption"
        >
          {author?.name}
        </TextLink>
        <Text className={clsx(styles.time, styles[classes])} variant="caption">
          <span className={clsx(styles.bullet, styles[classes])}>&#8226;</span>
          {toFriendlyDate(postDate)}
        </Text>
        {isPremium && (
          <On3IconPremiumPlus
            className={styles.on3Icon}
            height={40}
            width={14}
          />
        )}
      </div>
    </article>
  );
};
